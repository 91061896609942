import 'prismjs/themes/prism-tomorrow.css';
import "prismjs/plugins/line-numbers/prism-line-numbers.css";

// custom typefaces
import 'typeface-montserrat'
import 'typeface-merriweather'

import { wrapRootElement as wrap } from './wrap-root-element'

export const wrapRootElement = wrap
